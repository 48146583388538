import React, { Component } from "react";
import PropTypes from "prop-types";
import { noop, map, isEmpty, isEqual, capitalize } from "lodash";
import { Button, Input, SearchSelect, Select } from "../../../components/base";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import { NIS_SISWA_SEARCH } from "../../../constants/student.constant";
import { BATAL_SISWA_KELUAR_TABLE } from "../../../constants/student.constant";
import { commaFormatted, formatData, normalizeAmount } from "../../../utils/transformer.util";
import { STUDENT_TABLE_PPDB } from "../../../constants/student.constant";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { validateRequiredFields } from "../../../utils/validation.util";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class DaftarSiswaPPDBGeneral extends Component {
    constructor(props) {
      super(props);
      this._onFormChange = this._onFormChange.bind(this);
      this._onClickNext = this._onClickNext.bind(this);
      this._onChangeFilter = this._onChangeFilter.bind(this);
      this._onSearchContent = this._onSearchContent.bind(this);
      //this._onAddList = this._onAddList.bind(this);
      this._onClickAdd = this._onClickAdd.bind(this);
      this._getClassesOptions = this._getClassesOptions.bind(this);
      this._handleFormChange = this._handleFormChange.bind(this);
      this._onSearch = this._onSearch.bind(this);
    //   this._studentCount = this._studentCount.bind(this);
      this._onChangePage = this._onChangePage.bind(this);
      this.onClickCetak = this.onClickCetak.bind(this);
      this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
      // this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
      this.onClickEdit = this.onClickEdit.bind(this);
      this._onClickExcel = this._onClickExcel.bind(this);
      this._getPrmLevels = this._getPrmLevels.bind(this);
      this._getSchoolUnitsOptions = this._getSchoolUnitsOptions.bind(this);
  
      const { user } = this.props;
      const { school_unit } = user;
      const { levels_id } = school_unit;
      const this_year = new Date().getFullYear();

      const entrance = []
      for(var i=this_year; i<=this_year+1; i++) {
        var value = i.toString().substring(2)
        entrance.push({
          label: i,
          value: value,
        });
      }

      this.state = {
        page: 1,
        selected: {},
        content: initialContent,
        unchecked: {},
        filters: {},
        list: [],
        form: {
          value: {
            value_year: this_year.toString().substring(2),
            total_siswa: "",
            classes: [],
            // levels_id,
            listsiswa: [],
            classes_id: "",
            units_id: "",
            user_id: user.id,
          },
          error: {
            classes: "",
            levels: "",
            listsiswa: "",
          },
        },
        param: {
          entrance,
        },
        filtered: false,
        total_siswa: "",
      };
    }
  
    componentDidMount() {
      const {
        handleGetCodeOfAccount,
        location,
        handleGetClasses,
        handleGetLevels,
        user,
      } = this.props;
      const { school_unit, organizations_id, user_group } = user;
      const { levels_id } = school_unit;
      const paramTypes = ["classes", "classrooms"];
      const codeTypes = ["kesiswaan", "ppdb_smapldonbosko", "ppdb_smppl1yogya"]
      const { content, filters } = this.state;
      paramTypes.forEach((type) => {
        this._getParamOptionsAcademics(type);
      });
      let savedFilters =
      JSON.parse(window.localStorage.getItem("PPDBFilters")) || filters;
      let savedForm = JSON.parse(window.localStorage.getItem("PPDBForm")) || "";
      if (savedForm != "" && user.id != savedForm.value.user_id) {
        savedForm = "";
        savedFilters = filters;
      }
    this._initializeData(savedFilters);
      console.log(savedFilters)
      if (savedForm != "") {
        this.setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              levels_id: savedForm.value.levels_id,
              units_id: savedFilters.units_id ? savedFilters.units_id : "",
              classes_id: savedForm.value.classes_id,
              // period: filters.period,
              // update: true,
            },
          },
          filters: {
            ...prevState.filters,
            units_id: savedFilters.units_id ? savedFilters.units_id : "",
          },
        }));
      
  
      }
      // })
      
      // this._getPeriodsOptions();
    //   this._studentCount();

      this._getSchoolUnitsOptions();
      
    }

    async _initializeData(savedFilters = {}) {
      const { user } = this.props;
      const { school_unit, user_group } = user;
      const { levels_id } = school_unit;
      const paramTypes = ["levels"];
      paramTypes.forEach((type) => {
        this._getParamOptionsAcademics(type);
      });
  
      if (user_group.name !== "pusat") {
        // const paramTypes = ["classrooms", "levels"];
        // paramTypes.forEach((type) => {
        //   this._getParamOptionsAcademics(type);
        // });
        this._getClassesOptions({
          filters: { levels_id: [levels_id], units_id: [school_unit.id] },
        });
        await this._getPeriodsOptions({ units_id: school_unit.id });
      }
      await this._getSchoolUnitsOptions();
  
      this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            period: savedFilters.period,
            classrooms_id: savedFilters.classrooms_id,
            // savedFilters
          },
        }),
        () => {
          this._onSearchContent();
        }
      );
    }

    async _getSchoolUnitsOptions(filters={}) {
      const { getSchoolUnitsOptions } = this.props;
      const { form } = this.state;
      const { units_id } = form.value;
      const res = await getSchoolUnitsOptions(filters);
      const selectedUnits = res.find(item => item.value == units_id); 
      
      // console.log(units_id)
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            levels_units: selectedUnits ? selectedUnits.attributes.levels_id : '', 
          }
        },
        param: {
          ...prevState.param,
          units: res,
        },
      }));
    }
  

    async _getPrmLevels(filters = {}) {
      const { getPrmLevels, user } = this.props;
      const { organizations_id, school_unit } = user;
      const { units_id, levels_id } = school_unit;
      const res = await getPrmLevels(filters);
      filters.organizations_id = organizations_id;
  
      this.setState((prevState) => ({
        ...prevState,
        param: {
          ...prevState.param,
          levels: res,
        },
      }));
    }
  
    onClickCetak() {
      const { history } = this.props;
      history.push("/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit", {
        isEdit: true,
      });
    }
  
    _onClickExcel() {
      const { history } = this.props;
      history.push('/dashboard/kesiswaan/laporan/daftar-siswa-ppdb/cetak-excel');
    }
  
    async _getParamOptionsAcademics(type, filters = {}) {
      const { getParamOptionsAcademics, user } = this.props;
      const { school_unit, organizations_id } = user;
      if (type === "classrooms" || type === "classrooms") {
        filters.organizations_id = organizations_id;
        filters.units_id = school_unit.id;
      } else {
        delete filters.organizations_id;
      }
      const res = await getParamOptionsAcademics(filters, type);
      this.setState((prevState) => ({
        ...prevState,
        param: {
          ...prevState.param,
          [type]: res,
        },
      }));
    }
    // on formchange untuk form
    _handleFormChange(event) {
      const { name, value } = event.target;
      this.setState((prevState) => ({
        form: {
          value: {
            ...prevState.form.value,
            [name]: value,
          },
          error: {
            ...prevState.form.error,
            [name]: "",
          },
        },
      }));
    }
  
    _onSearch() {
      const { form } = this.state;
      const error = validateRequiredFields(form.value, ["year"]);
      this.setState((prevState) => ({
        form: {
          value: prevState.form.value,
          error,
        },
      }));
      if (isEmpty(error)) {
        this.setState({ filtered: true });
      }
    }
  
    async _getParamOptions(type, filters = {}) {
      const { getParamOptions, user } = this.props;
      const { organizations_id } = user;
      if (type === "classes" || type === "classrooms") {
        filters.organizations_id = organizations_id;
      } else {
        delete filters.organizations_id;
      }
      const res = await getParamOptions(filters, type);
      this.setState((prevState) => ({
        ...prevState,
        param: {
          ...prevState.param,
          [type]: res,
        },
      }));
    }
  
    async _getClassesOptions(filters = {}) {
      const { getClassesOptions, user } = this.props;
      const { school_unit } = user;
      const { levels_id } = school_unit;
      filters.levels_id = levels_id;
      filters.units_id = school_unit.id;
  
      const res = await getClassesOptions(filters);
      // console.log(res)
      this.setState((prevState) => ({
        ...prevState,
        param: {
          ...prevState.param,
          classes_prm: res,
        },
      }));
    }
  
    // async _getPeriodsOptions(filters = {}) {
    //   const { getPeriodsOptions, user } = this.props;
    //   const { organizations_id, school_unit } = user;
    //   const { units_id, levels_id } = school_unit;
    //   const res = await getPeriodsOptions(filters);
    //   filters.organizations_id = 3;
  
    //   this.setState((prevState) => ({
    //     ...prevState,
    //     param: {
    //       ...prevState.param,
    //       periods: res,
    //     },
    //   }));
    // }
  
    // async _studentCount(payload = {}) {
    //   const { handleStudentCount, user } = this.props;
    //   const { organizations_id, school_unit } = user;
    //   const { units_id, levels_id } = school_unit;
    //   const res = await handleStudentCount({
    //     ...payload,
    //     organizations_id: 3,
    //     units_id: school_unit.id,
    //   });
    //   // filters.organizations_id = organizations_id;
    //   // filters.units_id = units_id;
  
    //   this.setState((prevState) => ({
    //     ...prevState,
    //     total_siswa: res,
    //   }));
    // }
  
    _onFormChange(event) {
      const { user } = this.props;
      const { organizations_id } = user;
      const { name, value, dataset, checked, type } = event.target;
      const {
        inputType = "text",
        inputArray = false,
        arrayPosition = 0,
        fieldName,
      } = dataset;
      this.setState(
        (prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
  
          if (inputType === "number") {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === "checkbox") {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === "code_of_account") {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = "";
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
              },
            },
          };
        },
        () => {
          if (name === "levels_id") {
            this._getClassesOptions({ filters: { levels_id: [value] } });
          }
          if (name === "classes_id") {
            this._getParamOptionsAcademics("classrooms", {
              classes_id: value,
              organizations_id: organizations_id,
            });
          }
        }
      );
    }
  
    _onClickAdd(data) {
      const { form } = this.state;
      const { plafon } = form.value;
  
      plafon.push({
        tipePlafon: "",
        // divisi: '',
        nominal: 0,
        //period:'',
      });
  
      this.setState((prevState) => ({
        form: {
          value: {
            ...prevState.form.value,
            plafon,
          },
          error: {
            ...prevState.form.error,
          },
        },
      }));
    }
  
    async _onSearchContent(params = {}, type) {
      const { filters, form } = this.state;
      const { value } = form;
      const { subject_id, value_year } = value;
      const { year, jenis, units_id} = filters;
      const { user } = this.props;
      const { organizations_id } = user;
      this.setState(
        {
          content: initialContent,
        },
        async () => {
          try {
            const {
              handleListStudentPPDB,
              handleListStudentPPDBGeneral,
              handleListReportStudent,
              handleListStudentsPPDBUppRicci,
              handleListAchievement,
              user,
            } = this.props;
            const { school_unit, workingUnit, id, user_group, organizations_id } =
              user;
            const { levels_id, subdomain } = school_unit;
            const units_id_pst = units_id ? units_id.value : '';
            const ppdb_name_pst = units_id ? units_id.attributes.ppdb_name : '';
            const ppdb_name = user_group.name == 'pusat' ? ppdb_name_pst : school_unit.ppdb_name;

            

            let result = {};
            if(school_unit.ppdb_online == 1 && subdomain == null && organizations_id == 3) {
             result =  await handleListStudentPPDB({
                ...params,
                organizations_id: organizations_id,
                units_id: school_unit.id,
                filters: {
                  year: year ? year : value_year
                },
                type
              })
            }
            else if (school_unit.ppdb_online == 1 && subdomain !== null) {
             result = await handleListStudentPPDBGeneral({
                ...params,
                subdomain: subdomain,
                filters: {
                  year: year ? year : value_year
                },
              })
            }
            else if (school_unit.ppdb_online == 1 && subdomain == null && organizations_id == 4 || organizations_id == 5){
              console.log(units_id)  
              console.log(ppdb_name)
          
              result = await handleListStudentsPPDBUppRicci({
                ...params,
                organizations_id: organizations_id,
                units_id: user_group.name == 'pusat' ? units_id_pst : school_unit.id,
                type: ppdb_name,
                filters: {
                  year: year ? year : value_year
                },
              })
            }
           
            // }
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  listsiswa: result,
                },
              },
            }));
          } catch (err) {
            // if (this.isMount) {
            this.setState((prevState) => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  listsiswa: [],
                },
              },
            }));
          }
          // }
        }
      );
    }
  
    // on formchange untuk tabel
    onFormChange(event) {
      const { name, value, dataset } = event.target;
      const {
        inputArray = false,
        arrayPosition = 0,
        inputType = "text",
      } = dataset;
      this.setState((prevState) => {
        let formattedValue = value;
        let newPlafon = [];
        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
  
        if (inputArray) {
          newPlafon = prevState.form.value.plafon;
          newPlafon[arrayPosition][name] = value;
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { plafon: newPlafon }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              form: "",
              [name]: "",
            },
          },
        };
      });
    }
  
    _onChangeFilter(e) {
      const { target } = e;
      const { value, name } = target;
      const { user } = this.props;
      const { organizations_id, school_unit } = user;
      this.setState(
        (prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            [name]: value,
          },
        }),
        () => {
          window.localStorage.setItem(
            "PPDBFilters",
            JSON.stringify(this.state.filters)
          );
          window.localStorage.setItem(
            "PPDBForm",
            JSON.stringify(this.state.form)
          );
          console.log(this.state.filters)
          console.log(this.state.form)
          if (name === 'units_id') {
            const param = this.state.param.units;
            const levels_units = param.find(i => i.value == value.value)?param.find(i => i.value == value.value).attributes.levels_id:"";

            this.setState(prevState => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  classrooms_id: "",
                  levels_id: levels_units,
                  units_id : value,
    
                }
              }
            }));
            // this._getParamOptionsAcademics("classrooms", {
            //   levels_id: levels_units, organizations_id: organizations_id, units_id: value.value
            // });
            // this._getClassesOptions({filters : { levels_id: [levels_units], units_id: [value.value]}});
  
            // this._getPeriodsOptions({units_id: value.value})
            // this._studentCount({units_id: value.value})
          }
          this._onSearchContent()
        }
      );
    }
    _onChangePage(page) {
      const { listAmount, keywords } = this.state;
      const offset = listAmount * (page - 1);
      // this.handleGetData({
      this._onSearchContent({
        limit: listAmount,
        keywords,
        offset,
        page,
      });
    }
  
    _onClickNext() {
      window.scrollTo(0, 0);
      this.setState((prevState) => ({
        page: prevState.page + 1,
      }));
    }
  
    onClickEdit(val) {
      const { history } = this.props;
      history.push("/dashboard/kesiswaan/pendaftaran", {
        data: val,
        isEdit: true,
      });
    }

    renderUPPBayar(listsiswa){
      const row = [];
      let totalUPP = 0;
        map(listsiswa.UPPTotal, (data, idx) => {
          totalUPP+=Number(data.nominal)
        })
        row.push([<td>Rp. {commaFormatted(totalUPP)}</td>])
   
      return row;
    }

    renderTotalUPPAwal(listsiswa){
      const row = [];
      let totalUPPAwal = 0;
      map(listsiswa, (list) => {
        map(list.UPPAwal, (data, idx) => {
          totalUPPAwal+=Number(data.nominal)
        })
      })
        row.push([<td>Rp. {commaFormatted(totalUPPAwal)}</td>])
      return row;
    }

    renderTotalUPPBayar(listsiswa){
      const row = [];
      let totalUPPBayar = 0;
      let totalUPP = 0;
      map(listsiswa, (list) => {
        totalUPP = 0;
        map(list.UPPTotal, (data, idx) => {
          totalUPP+=Number(data.nominal)
        })
        totalUPPBayar+=totalUPP
      })
        row.push([<td>Rp. {commaFormatted(totalUPPBayar)}</td>])
      return row;
    }
  
    _renderButtons(data) {
      const { user } = this.props;
      const { user_group } = user;
      const button = {};
  
      return (
        <td>
          <div className="table__actions">
            <Button onClick={() => this.onClickEdit(data)} title="Edit" />
          </div>
        </td>
      );
    }
  
    render() {
      const {
        param,
        form,
        page,
        list = {},
        selected,
        checkedAll,
        content,
        filters,
        listAmount,
        total_siswa,
      } = this.state;
      const { entrance, units } = param;
      const { listsiswa, value_year } = form.value;
      const { classrooms_id = "", status_id = "", period = "" , year = ""} = filters;
      // console.log(total_siswa);
      const { classes = {}, levels = {}, user } = this.props;
      const { school_unit, user_group, organizations_id } = user;
      const presence_status = [
        { label: "Mengikuti", value: "mengikuti" },
        { label: "Tidak Mengikuti", value: "tidak" },
      ];
      const status = [
        { label: "Hadir", value: "HADIR" },
        { label: "Sakit", value: "SAKIT" },
        { label: "Izin", value: "IZIN" },
        { label: "Alpha", value: "ALPHA" },
      ];

      let TotalSeluruhUPPTotal = 0;
      // {map(listsiswa, (list, idx) => (
      //   map(list.UPPTotal, (data, idx) => (
      //     TotalSeluruhUPPTotal+=Number(data.nominal)
      //     ))
      // ))}
      let totalSPP = 0;
      let totalDPP = 0;
      let PotonganDPP = 0;
      let totalUKG = 0;

      let early = '';
      let prestasi = '';
      let lunas = '';
      let kakak_adik = '';

      let totalEarly = 0;
      let totalPrestasi = 0;
      let totalLunas = 0;
      let totalkakak_adik = 0;
      // console.log(totalUPP)
      // const entrance = [
      //   { label: "2020", value: "20" },
      //   { label: "2021", value: "21" },
      //   { label: "2022", value: "22" },
      // ];
      const units_find = units?units.find((i) => i.value == form.value.units_id.value):'';
      const units_label = units_find?units_find.label:'';
      const year_find = entrance?entrance.find((i) => i.value == value_year) : ''
      const year_label = year_find?year_find.label:'';
      const componentRef = React.createRef();
      return (
        <div className="manage-registration">
           {(user_group.name === 'pusat')? 
              <div className="absensi-rekap__custom-form-row">
                <div className="absensi-rekap__custom-form-row__field">
                <SearchSelect
                  noMargin
                  async={false}
                  name="units_id"
                  list={param.units}
                  inputArray
                  onClick={this._onChangeFilter}
                  placeholder="Pilih Unit"
                  value={form.value.units_id}
                  rightIcon="icon-search"
                  label="Pilih Unit"
                />
              </div>
            </div>
            :''}
           <div className="isi-bidang__custom-form-column">
            <div className="isi-bidang__custom-form-row__field">
            <Select
                name="year"
                label="Tahun Masuk"
                placeholder={'Pilihan'}
                onChange={this._onChangeFilter}
                data={entrance}
                // disabled
                value={year?year:value_year}
              />
              
          <div className="isi-bidang__custom-form-row__field"></div>
        
         </div>
       
           
      </div>
          <div className="beginning-balance__button-wrapper">
            <ReactToPrint
              trigger={() => (
                  <Button title="Cetak" />
              )}
              content={() => componentRef.current}
            />
            {organizations_id == 3?
            <Button
                title="Ekspor Excel"
                onClick={this._onClickExcel}
              />
              :
              <ReactHTMLTableToExcel
              id="export-to-xls-button"
              className="button "
              table="printable"
              filename={`Data Wawancara PPDB ${units_label} Tahun Ajaran ${year_label} - ${year_label+1} `}
              sheet={year}
              buttonText="Unduh ke Excel"
            />
            }

            </div>
            <form
              ref={componentRef}
            >
            <div className="manage-registration">
              <br></br>
                <h1>Daftar Siswa Jalur PPDB</h1>
                  {/* <h1>Total Siswa {school_unit.name} : {total_siswa}</h1> */}
                  <div className="fund-request__content">
                    <table className="table" id="printable" ref={componentRef}>
                      <thead>
                       <tr>
                        <th rowSpan={2}>No</th>
                        <th rowSpan={2}>No VA</th>
                        <th rowSpan={2}>Nama Lengkap Siswa</th>
                        <th rowSpan={2}>Total Usek</th>
                        <th rowSpan={2}>Total Ukeg</th>
                        <th rowSpan={2}>Total DPP</th>
                        <th colSpan={4}>Detail Potongan</th>
                        <th rowSpan={2}>Total Potongan DPP</th>
                       </tr>

                       <tr>
                          <th>Early Bird</th>
                          <th>Prestasi</th>
                          <th>Langsung Lunas</th>
                          <th>Kakak Adik</th>
                       </tr>
                      </thead>
                      <tbody>
                        {map(listsiswa, (list, idx) => (
                          //fungsi () gunanya agar bisa jalan
                          <>
                        {(() => {
                          // totalSPP = 0;
                          // totalDPP = 0;
                          // totalUKG = 0;
                        })()}

                          <tr key={`budget_row_${idx}`}>
                            <td style={{textAlign:'center'}}>{idx+1}</td>
                            <td style={{textAlign:'center'}}>{list.no_va}</td>
                            <td style={{textTransform:"capitalize"}}>{capitalize(list.fullname)}</td>
                            {list.SPPTotal!=''?
                           (map(list.SPPTotal, (data, idx) => (
                            <>
                              <td>Rp. {commaFormatted(data.total_usek)}</td>
                              {(() => {
                              totalSPP += Number(data.total_usek)
                              })()}
                            </>
                            ))) : (<td>Invoice Belum Dibuat</td>)}
                            {list.UKGTotal!=''?
                           (map(list.UKGTotal, (data, idx) => (
                            <>
                              <td>Rp. {commaFormatted(data.total_ukg)}</td>
                              {(() => {
                                totalUKG += Number(data.total_ukg)
                                })()}
                            </>
                            ))) : (<td>Invoice Belum Dibuat</td>)}
                          {list.DPPTotal!=''?
                           (map(list.DPPTotal, (data, idx) => (
                            <>
                              <td>Rp. {commaFormatted(data.total_dpp)}</td>
                              {(() => {
                                totalDPP += Number(data.total_dpp)
                                })()}
                            </>
                            ))) : (<td>Invoice Belum Dibuat</td>)}
                            
                            
                          
                          {list.detailPotongan!=''?
                            <>
                            {(() => {
                               early = list.detailPotongan.filter((i) => i.payments_id == 118)
                               prestasi = list.detailPotongan.filter((i) => i.payments_id == 119)
                               lunas = list.detailPotongan.filter((i) => i.payments_id == 120)
                               kakak_adik = list.detailPotongan.filter((i) => i.payments_id == 121)

                              })()}
                              {map(early,(item, idx) => (
                                ((() => {
                                totalEarly += item.nominal
                                })())

                              ))}
                              {map(prestasi,(item, idx) => (
                                ((() => {
                                totalPrestasi += item.nominal
                                })())

                              ))}
                              {map(lunas,(item, idx) => (
                                ((() => {
                                totalLunas += item.nominal
                                })())

                              ))}
                              {map(kakak_adik,(item, idx) => (
                                ((() => {
                                totalkakak_adik += item.nominal
                                })())

                              ))}
                            
                            {list.detailPotongan.find((i) => i.payments_id == 118)
                            ?<td>Rp.
                            {commaFormatted(list.detailPotongan.find((i) => i.payments_id == 118).nominal)}
                            </td>:<td>Tidak Ada Potongan</td>}

                            {list.detailPotongan.find((i) => i.payments_id == 119)
                            ?<td>Rp.
                            {commaFormatted(list.detailPotongan.find((i) => i.payments_id == 119).nominal)}
                            </td>:<td>Tidak Ada Potongan</td>}

                            {list.detailPotongan.find((i) => i.payments_id == 120)
                            ?<td>Rp.
                            {commaFormatted(list.detailPotongan.find((i) => i.payments_id == 120).nominal)}
                            </td>:<td>Tidak Ada Potongan</td>}

                            {list.detailPotongan.find((i) => i.payments_id == 121)
                            ?<td>Rp.
                            {commaFormatted(list.detailPotongan.find((i) => i.payments_id == 121).nominal)}
                            </td>:<td>Tidak Ada Potongan</td>}

                              
                              </>
                            :(<td colSpan={4}>Invoice Belum Dibuat</td>)}
                              


                          {list.PotonganDPP!=''?
                           (map(list.PotonganDPP, (data, idx) => (
                            <>
                              <td>Rp. {commaFormatted(data.potongan_dpp)}</td>
                              {(() => {
                                PotonganDPP += Number(data.potongan_dpp)
                                })()}
                            </>
                            ))) : (<td>Invoice Belum Dibuat</td>)}

                          </tr>
                          </>
                        ))}
                        <tr>
                          <td colSpan={3}> Total </td>
                          <td>Rp. {commaFormatted(totalSPP)}</td>
                          <td>Rp. {commaFormatted(totalUKG)}</td>
                          <td>Rp. {commaFormatted(totalDPP)}</td>
                          <td>Rp. {commaFormatted(totalEarly)}</td>
                          <td>Rp. {commaFormatted(totalPrestasi)}</td>
                          <td>Rp. {commaFormatted(totalLunas)}</td>
                          <td>Rp. {commaFormatted(totalkakak_adik)}</td>
                          <td>Rp. {commaFormatted(PotonganDPP)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </div>
      );
    }
  }
DaftarSiswaPPDBGeneral.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
DaftarSiswaPPDBGeneral.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
